<template>
	<div class="group-list">
		<no-data v-if="list.length<=0"></no-data>
		<van-pull-refresh v-model="isLoading" success-text="刷新成功" @refresh="onRefresh" v-else>
			<van-list finished-text="加载完毕" v-model="isloading" :finished="finished" @load="loadData">
				<div class="goodslist">

					<div class="goods-container" v-for="(item,index) in list" :key="index">
						<div class="goods-wrap" @click="toDetail(item.id)">
							<van-cell title-class="top-cell flex flex-row">
								<template slot="title">

									<van-image width="3rem" height="3rem" :src="item.images.length>0?item.images[0]:''" fit="cover">
										<template v-slot:loading>
											<van-loading type="spinner" size="20" />
										</template>
									</van-image>


									<div class="text-wrap">
										<div class="name-wrap clamp-1">
											<span class="text-theme1" style="font-size: 14px;">{{item.goods.good_name}}</span>
										</div>
										<div class="brand-wrap clamp-1">
											<span class="text-base">{{item.is_groupbuy==1?'拼团':'单买'}}</span>
										</div>
										<div class="price-wrap clamp-1">
											<span class="text-theme1" style="color: #f6a307;">{{item.is_groupbuy==1 ? '￥' + item.goods.groupbuy_price : '￥' + item.goods.alone_price}}</span>
										</div>
										<div class="price-wrap clamp-1">
											<span class="text-theme1">数量：x</span>
											<span class="text-theme1" style="margin-left: 3px;color: grey;">{{item.amount}}</span>
										</div>
										<div class="price-wrap clamp-1" style="display: flex;justify-content:space-between">
											<span>
												<span class="text-theme1">金额：</span>
												<span style="color: red;">￥{{item.final_price}}</span>
											</span>
											<span style="background-color: grey;padding: 7px;color: white;border-radius: 50% 0 0 50%;" v-show="item.status == -1">
												已取消
											</span>
											<span style="background-color: grey;padding: 7px;color: white;border-radius: 50% 0 0 50%;" v-show="item.status == 0">
												待支付
											</span>
											<span style="background-color: #ff9e03;padding: 7px;color: white;border-radius: 50% 0 0 50%;" v-show="item.group_status == 0 && item.status != -1 && item.status != 0 && item.status != 2">
												待成团
											</span>
											<span style="background-color: #409EFF;padding: 7px;color: white;border-radius: 50% 0 0 50%;" v-show="item.group_status == 1 && item.status != -1 && item.status != 0 && item.status != 2">
												已成团
											</span>
											<!-- <span style="background-color: #409EFF;padding: 7px;color: white;border-radius: 50% 0 0 50%;" v-show="item.status == 1">
												待核销
											</span> -->
											<span style="background-color: #09bb07;padding: 7px;color: white;border-radius: 50% 0 0 50%;" v-show="item.status == 2">
												已核销
											</span>
										</div>
										
										
										
									</div>

								</template>
							</van-cell>
						</div>
							
					</div>

				</div>
			</van-list>
		</van-pull-refresh>
	</div>
</template>

<script>
	import {
		DropdownMenu,
		DropdownItem,
		List,
		Row,
		Col,
		Panel,
		Button,
		Search,
		Icon,
		Overlay,
		Picker,
		Popup,
		Field,
		Cell,
		CellGroup,
		DatetimePicker,
		Uploader,
		SwitchCell,
		Checkbox,
		Toast,
		Sticky,
		PullRefresh,
		Loading,
		Progress
	} from "vant";
	import { groupbuy_user_orderlist } from '../../../../request/api.js'
	export default {
		components: {
			[DropdownMenu.name]: DropdownMenu,
			[DropdownItem.name]: DropdownItem,
			[List.name]: List,
			[Row.name]: Row,
			[Col.name]: Col,
			[Panel.name]: Panel,
			[Button.name]: Button,
			[Search.name]: Search,
			[Icon.name]: Icon,
			[Overlay.name]: Overlay,
			[Popup.name]: Popup,
			[Field.name]: Field,
			[Picker.name]: Picker,
			[Cell.name]: Cell,
			[CellGroup.name]: CellGroup,
			[DatetimePicker.name]: DatetimePicker,
			[Uploader.name]: Uploader,
			[SwitchCell.name]: SwitchCell,
			[Checkbox.name]: Checkbox,
			[Toast.name]: Toast,
			[Sticky.name]: Sticky,
			[PullRefresh.name]: PullRefresh,
			[Loading.name]: Loading,
			[Progress.name]: Progress
		},
		data() {
			return {
				page: 1,
				size: 10,
				list: [],
				isLoading: false,
				finished: false,
				isloading: false,
				activity_id: ''
			}
		},
		mounted: function() {
		    this.activity_id = this.$route.query.activity_id;
		    this.loadData();
		},
		methods: {
			async loadData(){
			    if (this.finished) {
			        return;
			    }
			    this.isloading = true;
			    // 获取列表
			    await groupbuy_user_orderlist({
					activity: this.activity_id,
					size: this.size,
					}).then(res => {
					this.isloading = false
			        if(res.code == 1){
			            //  还有更多数据page++
			            if (res.data.has_more == true){
			                this.size = this.size + this.size
			                this.finished = false;
							this.isloading = false
			            } else {
			                //没有数据了
			                this.finished = true
							this.isloading = false
			            }
			            this.list = res.data.data
						for (var i = 0; i < this.list.length; i++) {
							this.$set(this.list[i],'goods',JSON.parse(this.list[i].goods_info))
						}
						// console.log(this.list)
			        } else {
						this.isloading = false
						this.isLoading = false
						this.finished = true
			            this.$dialog({message:res.msg});
			        }
			    }).catch(err => {
					console.log(err);
					this.isloading = false
					this.isLoading = false
					this.finished = true
			        this.$dialog({
			            message: "团购数据获取失败，请重新刷新"
			        })
			    });
			
			    // 加载状态结束
			    this.loading = false;
			    this.isloading = false
			},
			onRefresh(){
				this.isLoading = false;
				this.finished = false;
				this.list = [];
				this.size = 10;
				this.loadData();
			},
			toDetail(id){
				this.$router.push({path: '/activity/marketing/group/orderDetail', query:{id:id, activity_id:this.activity_id}})
			}
		}
	}
</script>

<style lang="less" scoped>
	.group-list {
		.APP-field {
			width: 96%;
			padding: 2%;
			font-size: 12px;
			color: #757575;
			display: flex;
			flex-wrap: nowrap;
			justify-content: space-between;
			align-items: center;
		}

		.APP-field>div {
			width: 47%;
			text-align: center;
			padding: 10px 0;
			border: 1px solid #e7e7e7;
		}

		.top-button-wrap {
			display: flex;
			flex-flow: row nowrap;
			padding: 10px 3px;
			justify-content: center;

			button {
				width: 100px;
			}
		}


		// 公共商品样式
		.goods-wrap {
			display: flex;
			flex-flow: row nowrap;
			align-items: center;
			background-color: white;
			font-size: 0;
			overflow: hidden;

			.van-image {
				margin-right: 10px;
				border-radius: 5%;
			}

			.text-wrap {
				display: flex;
				flex-flow: column nowrap;
				justify-content: space-between;
				height: 3rem;
				flex: 1;

				font-size: 0;

				span {
					font-size: 12px;

				}

				.brand-wrap {
					flex: 1;
					// height: 17px;
				}

				.name-wrap {
					flex: 1;

					// height: 17px;
					.text-base {
						// font-size: 14px;
						// font-weight: bold
					}
				}

				.desc-wrap {
					// height: 35px;
					flex: 2;
					margin-bottom: 5px;
				}

				.price-wrap {
					// height: 17px;
					flex: 1;
					display: flex;
					flex-flow: row nowrap;
					align-items: center;

					.text-grey {
						text-decoration: line-through;
					}

					.text-theme3 {
						display: block;
						font-size: 18px;
						// margin-left: 5px;
					}
				}
			}


		}
		.Add-wrap {
		  position: fixed;
		  bottom: 20px;
		  right: 15px;
		  font-size: 45px;
		  color: #1989fa;
		}
	}
</style>
